export type LocationType = 'country' | 'community' | 'region' | 'city'

export type LocationTextsDTO = {
  langcode: string,
  label: string,
  long_description: string,
  short_description: string,
  slug: string,
}

export type LocationDTO = {
  uuid: string,
  locality_parents: Array<string>,
  label: string,
  descriptions: Array<LocationTextsDTO>,
  latitude: number,
  longitude: number,
  type: LocationType,
  images: Array<any>,
  slug: string,
  metadata: any,

  iso2: string,

  uuid_parent: string,
  political_parent: LocationDTO | undefined,

  payload: string[],
}

export type LocationTexts = {
  langcode: string,
  label: string,
  longDescription: string,
  shortDescription: string,
  slug: string,
}

export type LocationLandings = {
  spas: {
    url: string,
    payloadLength: number,
  },
  getAways: {
    url: string,
    payloadLength: number,
  },
  promotions: Record<string, { url: string, payloadLength: number, }>,
  treatments: Record<string, { url: string, payloadLength: number, }>,
}

export class Location {
  constructor(
    public uuid: string,
    public label: string,
    public parentUUID: string | null,
    public texts: Record<string, LocationTexts>,
    public type: LocationType,
    public latitude: number,
    public longitude: number,
    // ISO 2
    public countryCode: string,
    public slug: string,
    public landingURLs: LocationLandings,
  ) { }

  static from(dto: LocationDTO, landingURLs: LocationLandings): Location {
    const texts: Record<string, LocationTexts> = {
      es: {
        langcode: 'es',
        label: dto.label,
        slug: dto.slug,
        longDescription: '',
        shortDescription: '',
      }
    }
    dto.descriptions.forEach((descDTO) => {
      texts[descDTO.langcode] = {
        langcode: descDTO.langcode,
        label: descDTO.label,
        longDescription: descDTO.long_description,
        shortDescription: descDTO.short_description,
        slug: descDTO.slug,
      }
    })

    let parentUUID = dto.political_parent ? dto.political_parent.uuid : null
    if (!parentUUID && dto.uuid_parent) {
      parentUUID = dto.uuid_parent
    }

    return new Location(
      dto.uuid,
      dto.label,
      parentUUID,
      texts,
      dto.type,
      dto.latitude,
      dto.longitude,
      dto.iso2,
      dto.slug,
      landingURLs,
    )
  }
}

export type LocationMinifiedTexts = {
  langcode: string,
  slug: string,
}

export class LocationMinified {
  constructor(
    public uuid: string,
    public parentUUID: string | null,
    public label: string,
    public slug: string,
    public type: string,
    public texts: Record<string, LocationMinifiedTexts>,
    public landingURLs: LocationLandings,
  ) { }

  static fromLocation(dto: Location, targetLang: string) {
    let label = dto.label
    let slug = dto.slug

    const texts: Record<string, LocationMinifiedTexts> = {
      es: {
        langcode: 'es',
        slug: dto.slug,
      }
    }
    Object.entries(dto.texts).forEach(([langcode, trans]) => {
      texts[langcode] = { langcode, slug: trans.slug }
      if (targetLang === langcode) {
        label = trans.label
        slug = trans.slug
      }
    })
    return new LocationMinified(
      dto.uuid,
      dto.parentUUID,
      label,
      slug,
      dto.type,
      texts,
      dto.landingURLs,
    )
  }
}

export type LocationTree = {
  location: Location;
  childLocations: Array<LocationTree>;
}

type LocationCompo = LocationMinified | Location

export class LocationCollection {
  private locationByUUID: Record<string, LocationCompo>

  constructor(locations: Array<LocationCompo>) {
    this.locationByUUID = {}
    locations.forEach((loc) => {
      this.locationByUUID[loc.uuid] = loc
    })
  }

  public getParentFrom(loc: LocationCompo): LocationCompo | null {
    if (!loc.parentUUID) {
      return null
    }

    const maybe = this.locationByUUID[loc.parentUUID]
    if (!maybe) {
      return null
    }
    return maybe
  }

  public getCountryFrom(loc: LocationCompo): LocationCompo {
    let cursor = loc

    while (cursor && cursor.parentUUID && cursor.type !== 'country') {
      cursor = this.locationByUUID[cursor.parentUUID]
    }

    return cursor
  }
}
