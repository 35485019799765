<template>
  <div>
    <div>
      <div
        class="config-modal__content"
        :class="{displayed: modelValue}"
      >
        <div class="config-modal__content__header">
          <div>
            <slot name="header">
              {{ header }}
            </slot>
          </div>
          <button
            class="config-modal__content__header__icon"
            @click="emitOnCloseClick()"
          >
            <TimesIcon variant="neutral" />
          </button>
        </div>
        <div class="config-modal__content__body">
          <slot />
        </div>
        <div
          v-if="isApplyButtonDisplayed"
          class="config-modal__button-group"
        >
          <SplNuxtLink
            v-if="applyButtonAsLink"
            :to="applyButtonLinkUrl"
            :query="applyButtonLinkQuery"
            is-external
            :no-lang="applyButtonLinkNoLang"
            :is-disabled="applyButtonDisabled"
          >
            <SplButton
              :disabled="applyButtonDisabled"
              variant="acent"
              @click="emitOnApplyClick()"
            >
              Aplicar
            </SplButton>
          </SplNuxtLink>

          <SplButton
            v-else
            :disabled="applyButtonDisabled"
            variant="acent"
            @click="emitOnApplyClick()"
          >
            Aplicar
          </SplButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TimesIcon from '../icons/Times.icon.vue'
import SplButton from './SplButton.vue'
import SplNuxtLink from '../SplNuxtLink.vue'

import { useOnScreenWidthResize } from '~/core/composable/shared/useOnScreenWidthResize'

export default defineNuxtComponent({
  components: {
    TimesIcon,
    SplButton,
    SplNuxtLink,
  },
  props: {
    modelValue: {
      type: Boolean,
      default: false
    },
    isApplyButtonDisplayed: {
      type: Boolean,
      default: false,
    },
    applyButtonDisabled: {
      type: Boolean,
      default: false,
    },
    applyButtonAsLink: {
      type: Boolean,
      default: false,
    },
    applyButtonLinkNoLang: {
      type: Boolean,
      default: false,
    },
    applyButtonLinkUrl: {
      type: String,
      default: () => '',
    },
    applyButtonLinkQuery: {
      type: Object,
      default: () => ({}),
    },
    header: {
      type: String,
      default: '',
    }
  },

  setup(props, { emit }) {
    const emitOnApplyClick = () => {
      onModalClose()
      emit('update:modelValue', false)
      emit('onApplyClick')
    }
    const emitOnCloseClick = () => {
      onModalClose()
      emit('update:modelValue', false)
    }

    function onModalOnpen() {
      document.body.style.overflow = 'hidden'
    }

    function onModalClose() {
      document.body.style.overflow = 'auto'
    }

    function onScreenResize() {
      onModalClose()
      emitOnCloseClick()
    }

    useOnScreenWidthResize(onScreenResize)

    watch(() => props.modelValue, (value) => {
      if (value) {
        onModalOnpen()
      } else {
        onModalClose()
      }
    })

    return {
      emitOnApplyClick,
      emitOnCloseClick,
    }
  },
})
</script>

<style lang="scss" scoped>
.config-modal {
  &__content {
    @apply w-full;
    @apply h-0;
    @apply opacity-0;

    pointer-events: none;

    @apply fixed bottom-0 left-0;
    @apply bg-white;
    @apply p-20p;
    @apply pb-16;
    @apply z-30;
    @apply overflow-auto;

    @apply transition-all duration-500;

    &__header {
      @apply border-b-1 border-spl-secondary-dark;
      @apply py-4 mb-6;

      @apply text-spl-dark font-bold;

      @apply flex justify-between;
    }
  }

  &__content.displayed {
    @apply opacity-100;
    @apply h-full;

    pointer-events: auto;
  }

  &__button-group {
    @apply fixed;
    @apply bottom-0 left-0;

    @apply w-full;
    @apply bg-white;
    @apply border-t-1;

    @apply p-4;
    @apply text-center;
  }
}
</style>
