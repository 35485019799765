<template>
  <div
    v-if="sessionLocationEnabled"
    class="session-location-selector"
  >
    <SelectBox
      id="session-location-selector"
      variant="minified-light"
      :data="locationsTreeView"
      display-prop="label"
      item-label-as-slot
    >
      <template #label>
        <span>
          {{ currentSessionLocationLabel }}
        </span>
      </template>
      <template #item="{ element }">
        <div>
          <div class="session-location-selector__parent-item">
            <SplNuxtLink

              no-lang
              is-external
              :to="element.url"
            >
              {{ element.label }}
            </SplNuxtLink>
          </div>


          <template
            v-for="leaf in element.children"
            :key="leaf.uuid"
          >
            <div class="session-location-selector__child-item">
              <SplNuxtLink
                no-lang
                is-external
                :to="leaf.url"
              >
                {{ leaf.label }}
              </SplNuxtLink>
            </div>
          </template>
        </div>
      </template>
    </SelectBox>
  </div>
</template>

<script lang="ts">
import SplNuxtLink from '../../shared/SplNuxtLink.vue'
import SelectBox from '../../shared/form/SelectBox.vue'

import { useFetchProxy } from '~/core/composable/shared/useFetchProxy'
import { useSessionLocation } from '~/core/composable/shared/useSessionLocation'
import { useContext } from '~/core/composable/shared/useContext'

import type { LocationTree } from '~/core/ts/entity/Location'
import type { PromotionLanding } from '~/core/ts/entity/PromotionLanding'
import type { TreatmentLanding } from '~/core/ts/entity/TreatmentLanding'

type LocationSelectorItem = {
  uuid: string,
  parentUUID: string,
  label: string,
  url: string,
  children: Array<LocationSelectorItem>,
}

export default defineNuxtComponent({
  components: {
    SplNuxtLink,
    SelectBox,
  },
  async setup() {
    const sessionLocation = useSessionLocation()

    const route = useRoute()
    const context = useContext()

    async function getPromotionIfItIsTheCase(): Promise<PromotionLanding | null> {
      if (context.type.value !== 'promotions') {
        return null
      }

      return await useFetchProxy<PromotionLanding>(
        '/api/promotions/promotion-landing-by-uri',
        { method: 'post', body: { uri: route.path } }
      )
    }

    async function getTreatmentIfItIsTheCase(): Promise<TreatmentLanding | null> {
      if (context.type.value !== 'treatments') {
        return null
      }

      return await useFetchProxy<TreatmentLanding>(
        '/api/treatments/treatment-landing-by-uri',
        { method: 'post', body: { uri: route.path } }
      )
    }

    async function getFullLocationsTree(): Promise<Array<LocationSelectorItem>> {
      if (context.type.value === 'dynamic') {
        return []
      }

      const locationTree = await useFetchProxy<Array<LocationTree>>(
        '/api/locations/location-tree-with-landing-of-context', {},
      )

      return locationTree.map(convertLocationTreeLeafIntoLSItem)
    }

    const posiblePromotion = await getPromotionIfItIsTheCase()
    const posibleTreatment = await getTreatmentIfItIsTheCase()
    const locationsTree: Array<LocationSelectorItem> = await getFullLocationsTree()

    function convertLocationTreeLeafIntoLSItem(branch: LocationTree): LocationSelectorItem {
      const loc = branch.location

      let url = ''

      switch (context.type.value) {
        case 'homes':
        case 'spas': {
          const landing = loc.landingURLs.spas
          if (landing.payloadLength !== 0) {
            url = landing.url
          }
        } break

        case 'getAways': {
          const landing = loc.landingURLs.getAways
          if (landing.payloadLength !== 0) {
            url = landing.url
          }

        } break

        case 'promotions': {
          const landingDict = loc.landingURLs.promotions

          if (!posiblePromotion) {
            break
          }

          const landing = landingDict[posiblePromotion.tagUuid]
          if (!landing) {
            break
          }

          if (landing.payloadLength !== 0) {
            url = landing.url
          }

        } break

        case 'treatments': {
          const landingDict = loc.landingURLs.treatments

          if (!posibleTreatment) {
            break
          }

          const landing = landingDict[posibleTreatment.tagUuid]
          if (!landing) {
            break
          }

          if (landing.payloadLength !== 0) {
            url = landing.url
          }

        } break
      }

      return {
        uuid: loc.uuid,
        parentUUID: loc.parentUUID || '',
        label: loc.label,
        url,
        children: branch.childLocations.map(convertLocationTreeLeafIntoLSItem)
      }
    }

    const locationsTreeView = computed(() => {
      const storedLoc = sessionLocation.storedLocation.value
      if (!storedLoc) {
        return locationsTree
      }

      return locationsTree
        .filter((loc) => !!loc.url)
        .map((loc) => {
          return {
            ...loc,
            children: loc.children.filter((loc) => !!loc.url)
          }
        })
        .sort((a, b) => {
          if (a.uuid === storedLoc.uuid || a.uuid === storedLoc.parentUUID) {
            return -1
          }

          return a.label.localeCompare(b.label)
        })
    })

    const currentSessionLocationLabel = computed(() => {
      const current = sessionLocation.storedLocation.value
      if (!current) {
        return 'Toda España'
      }

      return current.label
    })

    const sessionLocationEnabled = sessionLocation.enabled

    return {
      sessionLocationEnabled,
      currentSessionLocationLabel,

      locationsTree,
      locationsTreeView,
    }
  },
})
</script>

<style lang="scss" scoped>
.session-location-selector {
  @apply pl-2;

  @apply w-full;

  @apply font-normal;

  @screen md {
    @apply w-300p;
  }

  @screen lg {
    @apply w-400p;
  }

  &__parent-item {
    @apply font-semibold;
  }

  &__parent-item:hover {
    @apply text-spl-primary-dark;
    @apply underline;
  }

  &__child-item {
    @apply pl-2;
    @apply py-1;
  }

  &__child-item:hover {
    @apply underline;
  }
}
</style>
